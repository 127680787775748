import GlobalConfigService from '@shared/services/global-config-service';
import Translations from '@shared/utils/translations';
import { log } from '@shared/utils/alpinejs-debugger';

export const initGlobals = () => {
    const translations = new Translations();

    window.$translations = translations.get.bind(translations);
    window.$setTranslations = translations.set.bind(translations);
    window.globalConfig = new GlobalConfigService();

    window.explosion = window.explosion || {};
    window.explosion.log = log;
}
