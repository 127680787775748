const getCategory = () => import('@/pages/category/index');
const getRma = () => import('@/pages/rma/index');
const getCms = () => import('@/pages/cms-page');
const getProduct = () => import('@/pages/product');
const getBlog = () => import('@/pages/blog');

export default {
    default: () => import('@/pages/default'),
    'catalogsearch_result_index': () => import('@/pages/search'),
    'catalog_category_view': getCategory,
    'checkout_cart_index': () => import('@/pages/cart'),
    'checkout_onepage_success': getCms,
    'checkout_cart_configure': getProduct,
    'catalog_product_view': getProduct,
    'blog_index_index': getBlog,
    'blog_post_view': getBlog,
    'blog_category_view': getBlog,
    'blog_archive_view': getBlog,
    'blog_tag_view': getBlog,
    'blog_expert_view': getBlog,
    'premium_account_customer_index': () => import('@/pages/create-premium-account'),
    'cms_index_index': getCms,
    'cms_page_view': getCms,
    'magento_rma_returns_create': getRma,
    'magento_rma_returns_view': getRma,
    'magento_rma_guest_create': getRma,
    'magento_rma_guest_view': getRma,
    'wishlist_index_configure': getProduct
};
